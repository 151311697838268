import React, { createContext, useState } from "react";
import "./App.css";
import { Context } from "./store/store";
import Claims from "./Screens/Claims/Claims";
import { BrowserRouter } from "react-router-dom";
import RouterConfig from "./navigation/RouterConfig";
import { createTheme, ThemeProvider } from "@mui/material";
import SnackbarAlert from "./Screens/Snackbar/Snackbar";

const context = {
    claims: {
        projects: [],
        activeProject: {},
        summary: {},
    },
    translation: {},
    user_id: "ec0732c9-9e59-460d-b496-9830fd7d272b",
    model: "gpt-3.5-turbo",
    claimsNew: {
        projects: [],
        selectedHome: "Home",
        selectedPorject: {},
        filterDocuments: [],
        compare: {},
        parties: {},
    },
};

const theme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    verticalAlign: "baseline",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    height: "2rem",
                    borderRadius: "6px",
                    "&:hover": {
                        color: "#007BFF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    background: "#fff",
                    "&:hover": {
                        background: "#fff",
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
    },
});

function App() {
    const [contextStore, setContext] = useState<any>(context);
    return (
        <ThemeProvider theme={theme}>
            <Context.Provider value={{ contextStore, setContext }}>
                <SnackbarAlert />
                <RouterConfig />
            </Context.Provider>
        </ThemeProvider>
    );
}

export default App;
